import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const RodzinnyPage = () => (
  <Layout>
    <SEO title="Rodzinny Weekend w Gdańsku" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/rodzinny-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>
            MAMY NADZIEJĘ, ŻE ZOBACZYMY SIĘ Z TOBĄ I TWOJĄ RODZINĄ W GDAŃSKU
          </h3>
          <h1>Rodzinny Weekend w Gdańsku!</h1>
          <p>
            Jeżeli chcecie spędzić przyjemny, rodzinny weekend poza swoim
            miejscem zamieszkania, to postawcie na Gdańsk. Przede wszystkim
            zapraszamy na rodzinny dzień podczas rejsu statkiem. Na pokładzie
            jest sporo miejsca dla podziwiania nadmorskiego krajobrazu, muzyka
            na żywo oraz bar więc każdy znajdzie tu coś dla siebie. Jeżeli zaś
            pragniecie spędzić trochę czasu jedynie we dwoje, to mamy na to
            rozwiązanie. Proponujemy romantyczny wieczór z elegancką kolacją,
            podczas gdy Wasze pociechy pod opieką profesjonalnego animatora mogą
            świetnie się bawić w sali zabaw. Zobaczycie także Gdańską Zatokę,
            trójmiejskie plaże oraz Centrum Experyment w Gdyni. Zachęcamy do
            kontaktu z nami, aby dowiedzieć się więcej o tej ofercie, która może
            być dostosowana także do Waszych indywidualnych potrzeb.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>
                  Spacer z przewodnikiem szlakiem najważniejszych zabytków
                  Gdańska w połączeniu z opowiadaniem legend oraz dla dzieci
                  mała gra miejska „ w poszukiwaniu skarbu ”
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>
                  Zwiedzanie Centrum Experyment w Gdyni – nauka przez zabawę
                </li>
                <li>
                  Zwiedzanie Akwarium, wjazd kolejka linową na Kamienną Górę w
                  Gdyni, spacer po plaży i po nadmorskim bulwarze
                </li>
                <li>Obiad w restauracji z widokiem na Zatokę Gdańską</li>
                <li>
                  Zabawa w parku rozrywki Loopy’s World, a dla rodziców kolacja
                  w romantycznej restauracji
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Rejs zabytkowym statkiem stylizowanym na XVII w. po porcie
                  gdańskim
                </li>
                <li>Szanty na pokładzie statku </li>

                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default RodzinnyPage
